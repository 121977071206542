<template>
  <div>
    <b-table-simple v-if="additional_attributes.length" bordered>
      <b-tbody>
        <b-tr v-for="(attr, attr_index) in additional_attributes" :key="`${attr_index}`">
          <b-td>{{ attr }}</b-td>
          <b-td>
            <b-input-group v-if="editing_attr === attr">
              <b-form-input
                v-model="attr_value"
              />
              <b-input-group-append>
                <b-button-group>
                  <b-button variant="flat-success" @click="submitChange(attr, attr_value)">
                    <feather-icon icon="SaveIcon" />
                  </b-button>
                  <b-button variant="flat-danger" @click="cancelEdit">
                    <feather-icon icon="XCircleIcon" />
                  </b-button>
                </b-button-group>
              </b-input-group-append>
            </b-input-group>
            <span v-else>
              <span>
                {{ requirement.properties[attr] }}
              </span>
              <b-button
                variant="flat-secondary"
                style="float: right"
                size="sm"
                @click="editAttribute(attr)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </span>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <em v-else style="text-align: center; display: block">No Additional Attributes</em>
    <div v-if="new_attribute">
      <hr>
      <b-row>
        <b-col>
          <label for="">Attribute Name</label>
          <b-form-input v-model="new_attribute.attribute" :state="new_attribute_status" />
          <b-form-invalid-feedback>
            <strong>{{ new_attribute.attribute }}</strong> {{ msg }}
          </b-form-invalid-feedback>
        </b-col>
        <b-col>
          <label for="">Value</label>
          <b-form-input v-model="new_attribute.value" />
        </b-col>
      </b-row>
      <b-button-group class="mt-1">
        <b-button :disabled="invalidAttribute(new_attribute.attribute)" variant="flat-success" @click="submitChange(new_attribute.attribute , new_attribute.value)">
          Add
        </b-button>
        <b-button variant="flat-secondary" @click="cancelNewAttribute">
          Cancel
        </b-button>
      </b-button-group>
    </div>
    <b-button
      v-else
      v-b-popover.right.hover="'Create New Attribute'"
      class="mt-1"
      variant="flat-success"
      @click="createNewAttribute"
    >
      <feather-icon icon="PlusCircleIcon" />
    </b-button>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import coreService from '@/libs/api-services/core-service'

export default {
  name: 'AdditionalAttributes',
  data() {
    return {
      additional_attributes: [],
      all_attributes: [],
      editing_attr: null,
      attr_value: '',
      new_attribute: null,
      msg: '',
    }
  },
  computed: {
    ...mapState({
      requirement: state => state.requirements.selected_requirement,
    }),
    ...mapGetters({
      protectedReqAttributes: 'constants/protectedReqAttributes',
    }),
    new_attribute_status() {
      return this.invalidAttribute(this.new_attribute.attribute) ? false : null
    },
  },
  mounted() {
    this.getAttributes()
  },
  methods: {
    getAttributes() {
      const params = {
        model: this.$store.state.model.id,
      }
      this.protected_attributes = this.protectedReqAttributes.map(attr => attr.value)
      coreService.get(`/v1/legacy/requirements/attributes/${this.$store.state.requirements.selected_requirement.properties.id}`, { params }).then(({ data }) => {
        this.all_attributes = data.attributes
        this.additional_attributes = data.additional_attributes
      })
    },
    editAttribute(attr) {
      this.editing_attr = attr
      this.attr_value = this.requirement.properties[attr]
    },
    cancelEdit() {
      this.editing_attr = null
      this.attr_value = ''
    },
    createNewAttribute() {
      this.new_attribute = {
        attribute: '',
        value: '',
      }
    },
    invalidAttribute(attr) {
      let invalid = true
      if (attr === '') {
        this.msg = 'field can not be empty'
      } else if (this.protected_attributes.includes(attr)) {
        this.msg = 'is a protected requirement field'
      } else if (this.additional_attributes.includes(attr)) {
        this.msg = 'is already a requirement field'
      } else {
        invalid = false
      }
      return invalid
    },
    cancelNewAttribute() {
      this.new_attribute = null
    },
    submitChange(attribute, value) {
      const obj = {}
      obj[attribute] = value
      this.$store.dispatch('requirements/editRequirement',
        { id: this.requirement.properties.id, requirement: { new_attrs: obj } }).then(() => {
        this.cancelEdit()
        this.cancelNewAttribute()
        this.getAttributes()
        this.$store.dispatch('requirements/reselectRequirement')
      })
    },
  },
}
</script>

<style scoped>

</style>
