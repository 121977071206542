<template>
  <div>
    <TestsTable :items="tests" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TestsTable from '@/components/Tests/TestsTable.vue'
import coreService from '@/libs/api-services/core-service'

export default {
  name: 'RequirementTests',
  components: {
    TestsTable,
  },
  data: () => ({
    tests: [],
  }),
  computed: {
    ...mapState({
      requirement_id: state => state.requirements.selected_requirement.properties.id,
    }),
  },
  mounted() {
    this.fetchRequirementTests()
  },
  methods: {
    fetchRequirementTests() {
      coreService
        .get(`/v1/legacy/requirement/${this.requirement_id}/tests`)
        .then(({ data }) => {
          this.tests = data
        })
        .catch(e => console.error(e))
    },
  },
}
</script>
